body {
    background-color: rgb(0, 0, 0);
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}



.main-title-bg h1 {
    background: #3636369e;
    padding: 11px 0px;
    margin: 0px;
}

.main-title-bg {
    margin-bottom: 30px;
}

.main-title-bg p {
    text-align: center;
    margin: 0px;
    margin-top: 9px;
    color: #ffffff;
    font-weight: bold;
}


h1 {
    text-align: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-family: sans-serif;
}

h3 {
    width: 80%;
    margin: auto;
    margin-bottom: 30px;
    font-size: 30px;
    letter-spacing: 1px;
    color: red;
}

@media (max-width:700px) {
    h1 {
        font-size: 24px;
        padding: 0px 12px;
        margin-bottom: 23px;
    }

    h3 {
        width: 90%;
        font-size: 24px;
        margin-bottom: 20px;
        color: red;
    }
}

.homecards-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
    
}

@media (max-width:800px) {
    .homecards-cont {
        flex-wrap: wrap;
        justify-content: space-evenly;
        flex-direction: row;
        min-height: 50vh;
    }


}


.show-in-mini {
    display: none;
    color: #db61f0;
}

@media (max-width:800px) {
    .show-in-mini {
        display: block;
    }

    .main-title-bg p {
        font-size: 11px;
        letter-spacing: 1px;
    }
}



footer {
    background-color: red;
    padding: 26px 13px;
    margin-top: 30px;
}

footer p {
    text-align: center;
    margin: 0px;
    font-size: 14px;
}

footer span {
    font-weight: bold;
}

footer a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 14px;
}

@media (max-width:800px) {
    footer {
        background-color: red;
        padding: 8px 13px;
    }

    footer p {
        text-align: center;
        margin: 0px;
        font-size: 12px;
    }

    footer span {
        font-weight: bold;
    }

    footer a {
        text-decoration: none;
        color: black;
        font-weight: bold;
    }
}

@media (min-width:800px) {
    .homecards-cont {
        min-height: 80vh;
    }
}

#about-us{
    width: 90%;
    margin: auto;
    text-align: left;
}

.pwoli{
    width: 95%;
    margin: auto;
    font-size: 16px;
    margin-bottom: 30px;
}

.earphones{
    font-size: 18px;
    color: #ff5ce6;
}

@media (min-width:800px) {
    #about-us{
        width: 80%;
    }

    .pwoli{
        width: 80%;
        font-size: 25px;
    }

    .earphones{
        font-size: 27px;
    }
}




@keyframes floating {
    0%, 100% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-5px);
    }
  }
  
  @keyframes particles {
    0% {
      transform: translateZ(0, 0);
      opacity: 1;
    }
  
    100% {
      transform: translate(190px, 50px);
      opacity: 0;
    }
  }
  
  .button {
    position: relative;
    background: linear-gradient(171deg, #6f1e1e, #592fe7);
    display: inline-block;
    background-size: 200% auto;
    border: 1px solid;
    border-radius: 25px;
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    font-size: 18px;
    text-transform: uppercase;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    animation: floating 3s ease-in-out infinite;
    transition: color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    font-weight: bold;
  }
  
  .button::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 30px;
    box-shadow: 0 0 20px #FF7730;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    width: 5px;
    height: 5px;
    background:white;
    animation: particles 2s infinite;
  }
  
  .button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* .button:hover {
    color: #FFB900;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4);
    transform: scale(1.05);
    background-position: right center;
    background: linear-gradient(to right, #FFB900, #FF7730);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  } */

  .tapot{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 70px 0px;
  }

  .feedbackform{
    width: 100%;
  }

  .pwoli-feed{
    text-align: center;
    margin: 40px 0px;
    font-size: 24px;
    color: #ff00f7d6;
  }


  @media (max-width:600px) {
    #about-us p , li{
        font-size: 14px;
    }

    .tapot{
        margin: 40px 0px;
    }

    .tapot button{
        letter-spacing: 1px;
        font-size: 14px;
    }

    .pwoli-feed{
        font-size: 17px;
    }
}

.makelarge{
    font-size: 18px;
}

.waitmesg{
    width: 60%;
    color: rgb(249, 105, 105);
    text-align: center;
}

@media (max-width:600px) {
    .makelarge{
        font-size: 18px;
    }
    
    .waitmesg{
        width: 90%;
        font-size: 15px;
        line-height: 25px;
    }
}